import React from "react";
import "./css/style.css";
import PrivacyPolicySection from "./components/PrivacyPolicySection"

import ContactUsNew from "./components/ContactUsNew";
import ContactFiller from "./components/ContactFiller";

export default function PrivacyPolicy() {
  return (
    <>

      <PrivacyPolicySection />
      <ContactUsNew />

      {/* <Chatbot /> */}
    </>
  );
}
