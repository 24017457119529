import React from 'react'

const MagicButton = ({
  title,
  icon,
  type,
  position,
  handleClick,
  otherClasses
} : {
  title: string;
  type: any;
  icon: React.ReactNode;
  position: string;
  handleClick?: () => any;
  otherClasses?: string;
}) => {
  return (
    <button type={ type } onClick = { handleClick } className="relative w-full inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none md:w-60 md:mt-10">
    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#7e6eff_0%,#ffffff_50%,#7e6eff_100%)]" />
    <span className="{` inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-gray-950 px-7 py-1 text-sm font-medium text-white backdrop-blur-3xl gap-2 ${otherClasses} `}">
      { position === 'left' && icon }
      { title }
      { position == 'right' && icon }
    </span>
    </button>
  )
}

export default MagicButton