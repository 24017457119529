import React, { useState, useCallback } from "react";
import { FaMapMarkerAlt, FaChevronRight, FaEnvelope } from "react-icons/fa";
import { Label } from "./ui/PortfolioLabel";
import { Input } from "./ui/PortfolioInput";
import MagicButton from "./ui/MagicButton";
import { cn } from "../lib/utils";

export default function ContactUsNew() {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    designation: "",
    phone_number: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    if (!apiUrl) {
      setErrorMessage("API URL is not defined.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong");
      }

      const data = await response.json();
      console.log("Form Data Submitted: ", data);

      setFormData({ name: "", company: "", email: "", designation: "", phone_number: "" });
      setSuccessMessage(data.message || "Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form: ", error);
      setErrorMessage(error instanceof Error ? error.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <section>
      <div className="mx-auto max-w-6xl px-8">
        <div className="py-12 md:py-20">
          <div className="mx-auto px-4 sm:px-6 max-w-6xl text-center">
            <h1
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
              className="text-4xl md:text-4xl lg:text-6xl font-light mx-auto text-center mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white"
            >
              Contact Us
            </h1>
          </div>

          <div className="grid gap-8 mt-10 md:mt-20 md:grid-cols-2 items-start">
            <div className="md:mr-8 py-8 px-4 md:px-8 md:py-12 rounded-2xl border-[1px] border-indigo-400/50">
              <form onSubmit={handleSubmit}>
                {["name", "company", "email", "designation", "phone_number"].map((field) => (
                  <div key={field} className="flex flex-col space-y-2 w-full mb-4">
                    <Label htmlFor={field}>
                      {field
                        .replace("_", " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Label>
                    <Input
                      required
                      placeholder={`Enter Your ${field.replace("_", " ").replace(/\b\w/g, (char) => char.toUpperCase())}`}
                      type={field === "email" ? "email" : "text"}
                      name={field}
                      value={formData[field as keyof typeof formData]}
                      onChange={handleChange}
                    />
                  </div>
                ))}

                <div className="mt-10 md:mt-0 w-full">
                  <MagicButton type="submit" title={loading ? "Please Wait" : "Submit"} icon={<FaChevronRight />} position="right" />
                </div>
              </form>
            </div>

            <div className="flex justify-center md:justify-end">
              <div className="relative w-full max-w-lg flex flex-col mt-10 justify-between h-full">
                <div className="bg-[#151529] p-6 rounded-2xl shadow-lg">
                  <h3 className="text-xl font-normal text-gray-300 flex items-center">
                    <FaMapMarkerAlt className="mr-2 text-indigo-400" /> Our Location
                  </h3>
                  <p className="mt-3 text-sm text-gray-400">
                    1st Floor, Oberoi Commerz II Building, Oberoi Garden City, Mohan Gokhale Road, Off Western Express Highway,<br />
                    Goregaon (E), Mumbai - 400063, India
                  </p>
                  <div className="mt-4">
                    <a
                      href="https://www.google.com/maps?q=1st+Floor,+Oberoi+Commerz+II+Building,+Oberoi+Garden+City,+Mohan+Gokhale+Road,+Off+Western+Express+Highway,+Goregaon(E),+Mumbai-+400063,+India"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-400 hover:underline"
                    >
                      View on Google Maps
                    </a>
                  </div>
                </div>
                <div className="mt-10 text-center md:text-left">
                  <p className="text-white mt-4 font-light text-2xl md:text-3xl">Your thoughts matter to us!</p>
                  <p className="mt-4 mb-10 md:mb-0 text-base text-indigo-300 leading-relaxed">
                    Our team is ready to assist you with whatever you need. Got a question? We're all ears!
                  </p>
                  <a href="mailto:amy@hygaar.com">
                    <MagicButton type="" title="Mail Us" icon={<FaEnvelope />} position="right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(successMessage || errorMessage) && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-25">
          <div className="bg-gray-900 p-8 rounded-xl shadow-lg w-auto relative">
            <button onClick={closeModal} className="absolute top-1 right-2 text-white text-xl">
              &times;
            </button>
            <p className="mt-2 text-white">{successMessage || errorMessage}</p>
          </div>
        </div>
      )}
    </section>
  );
}
