import { useState, useEffect, useRef } from "react";
import { FaMousePointer } from "react-icons/fa";

const cardData = [
  {
    image: "/images/Webstore.webp",
    title: "Static Catalogue Image to Video",
    video: `${process.env.PUBLIC_URL}/images/Webstore.mp4`,
    content:
      "Effortlessly transform your product images into dynamic, ready-to-use videos with our automated solution. Perfect for e-commerce and D2C brands managing ever-changing SKUs, we deliver eye-catching, branded videos—complete with text, images, and music—faster and more cost-effectively for both web stores and retargeting campaigns.",
  },
  {
    image: "/images/BrowsingHistory.webp",
    title: "Videos from Browsing History",
    video: `${process.env.PUBLIC_URL}/images/BrowsingHistory.mp4`,
    content:
      "Engage your visitors by focusing on what matters most to them. Our Browser History Video tool lets you access each user's unique browsing history through our APIs, enabling you to create customized videos that showcase the products and content they find most relevant.",
  },
  {
    image: "/images/AbandonedCart.webp",
    title: "Videos from Abandoned Cart",
    video: `${process.env.PUBLIC_URL}/images/AbandonedCart.mp4`,
    content:
      "Boost your sales with our intelligent abandoned cart engagement tool. It creates personalized videos showcasing the exact items users left in their carts, motivating them to return and complete their purchases.",
  },
  {
    image: "/images/EmailContent.webp",
    title: "Email to Video",
    video: `${process.env.PUBLIC_URL}/images/EmailtoVideo.mp4`,
    content:
      "Elevate your email communications with our Email to Video tool. Effortlessly transform your basic HTML emails into immersive and engaging videos using our AI-powered dashboard.",
  },
];

export default function Workflows() {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [centeredIndex, setCenteredIndex] = useState<number | null>(null);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1440);

    if (window.innerWidth <= 1440) {
      const handleScroll = () => {
        let centerVideo = null;
        let centerDist = Number.MAX_VALUE;

        cardRefs.current.forEach((card, index) => {
          if (card) {
            const rect = card.getBoundingClientRect();
            const centerY = window.innerHeight / 2;
            const dist = Math.abs(rect.top + rect.height / 2 - centerY);

            if (dist < centerDist) {
              centerDist = dist;
              centerVideo = index;
            }
          }
        });

        setCenteredIndex(centerVideo);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const handleMouseEnter = (index: number) => {
    if (!isMobile) {
      setHoveredIndex(index);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setHoveredIndex(null);
    }
  };

  return (
    <section>
      <div className="mx-auto w-full px-8 max-w-6xl">
        <div className="pb-3 w-full md:pb-6">
          <h1
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="300"
            className="text-4xl md:text-4xl lg:text-6xl mx-auto text-center font-light relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white"
          >
            It's Feature Packed & Powerful
          </h1>

          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
            <div className="group my-6 md:my-20 mx-auto grid items-start lg:grid-cols-1">
              {cardData.map((card, index) => (
                <div
                  key={index}
                  ref={(el) => (cardRefs.current[index] = el)}
                  className="group/card my-6 relative h-full p-px transition-all duration-300"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    opacity: hoveredIndex === index ? 1 : 0.8,
                    transition: "transform 0.9s ease-out, opacity 0.3s ease-out",
                    zIndex: hoveredIndex === index ? 2 : 1,
                  }}
                >
                  <div className="relative z-20 h-full bg-gray-950">
                    <div className="flex flex-col justify-center items-center">
                      <div className="w-full lg:w-[40vw] order-2 md:h-[50vh] border-[1px] border-indigo-400/50 h-[26vh] rounded-2xl">
                        {(hoveredIndex === index || centeredIndex === index) ? (
                          <video
                            ref={(el) => (videoRefs.current[index] = el)}
                            className="w-full h-full rounded-2xl object-cover"
                            autoPlay
                            loop
                            muted
                            preload="auto"
                            playsInline
                          >
                            <source src={ card.video } type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <div className="w-full flex items-end justify-start h-full">
                            <img
                              className="w-full h-full rounded-2xl object-cover"
                              src={card.image}
                              alt={`Workflow ${index + 1}`}
                            />
                            {!isMobile && (
                              <div className="btn-sm opacity-0 md:opacity-100 m-10 absolute rounded-full bg-[#151529] px-4 py-2 text-md font-normal text-white">
                                <FaMousePointer className="me-2" />
                                Hover to Play Video
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="mb-10 order-1">
                        <span className="btn-sm relative rounded-full bg-[#151529] px-2.5 py-0.5 text-xs font-normal hover:bg-gray-800/60">
                          <span className="text-white bg-clip-text text-transparent px-2 py-1 text-base">
                            {card.title}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}