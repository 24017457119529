import React, { useState } from "react";
import { FaChevronLeft } from 'react-icons/fa';


export default function PrivacyPolicySection() {

  return (
    <section>
      <div 
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="300"
        className="mx-auto max-w-6xl p-8"
      >
        <div className="py-12 md:py-20">
          <div className="mx-auto px-4 text-indigo-200/80 sm:px-6 max-w-6xl text-start">
            
            <h1 className="text-4xl md:text-4xl lg:text-6xl font-light mx-auto text-center mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white">
                Privacy Policy - HyGaar
            </h1>

            <h2 className="text-2xl font-semibold mt-10 text-white mb-2">Last updated: February 26, 2025</h2>
            <p className="mb-4 mt-10">
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p className="mb-4">
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
            <h2 className="text-2xl font-semibold text-white mt-6 mb-2">Interpretation and Definitions</h2>
            <h3 className="text-xl text-white font-semibold mt-4 mb-2">Interpretation</h3>
            <p className="mb-4">
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
            <h3 className="text-xl font-semibold text-white mt-4 mb-2">Definitions</h3>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>Company:</strong> Hygaar Tech Service Private Limited.</li>
                <li><strong>Cookies:</strong> Small files placed on Your device by a website.</li>
                <li><strong>Service:</strong> The Website (https://www.hygaar.com/).</li>
                <li><strong>Usage Data:</strong> Data collected automatically when using the Service.</li>
                <li><strong>You:</strong> The individual accessing or using the Service.</li>
            </ul>
            <h2 className="text-2xl text-white font-semibold mt-6 mb-2">Collecting and Using Your Personal Data</h2>
            <h3 className="text-xl text-white font-semibold mt-4 mb-2">Types of Data Collected</h3>
            <h4 className="text-lg font-medium mt-4">Personal Data</h4>
            <p className="mb-4">
                While using Our Service, We may ask You to provide Us with certain personally identifiable information, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, ZIP/Postal code, City</li>
            </ul>
            <h4 className="text-lg font-medium mt-4">Usage Data</h4>
            <p className="mb-4">
                Usage Data is collected automatically when using the Service and may include details such as IP address, browser type, and pages visited.
            </p>
            <h3 className="text-xl font-semibold mt-4 text-white mb-2">Tracking Technologies and Cookies</h3>
            <p className="mb-4">
                We use Cookies and similar tracking technologies to track activity on Our Service and store certain information.
            </p>
            <h4 className="text-lg font-medium mt-4">Types of Cookies Used:</h4>
            <ul className="list-disc pl-6 mb-4">
                <li><strong>Necessary Cookies:</strong> Essential for authentication and preventing fraud.</li>
                <li><strong>Functionality Cookies:</strong> Remember choices like login details and language preference.</li>
            </ul>
            <h2 className="text-2xl font-semibold mt-6 text-white mb-2">Use of Your Personal Data</h2>
            <p className="mb-4">
                The Company may use Personal Data for various purposes, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>To provide and maintain our Service.</li>
                <li>To manage Your Account.</li>
                <li>To contact You via email, phone, or SMS.</li>
                <li>To improve our Service, products, and marketing strategies.</li>
            </ul>
            <h2 className="text-2xl font-semibold mt-6 mb-2 text-white">Security of Your Personal Data</h2>
            <p className="mb-4">
                We take security measures to protect Your Personal Data but cannot guarantee absolute security due to inherent risks in data transmission.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2 text-white">Children's Privacy</h2>
            <p className="mb-4">
                Our Service does not address anyone under the age of 13. If we discover that a child under 13 has provided us with Personal Data, we take steps to remove it.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2 text-white">Contact Us</h2>
            <p className="mb-4">
                If you have any questions about this Privacy Policy, You can contact us:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>By email: amy@hygaar.com</li>
                <li>By visiting our website: <a href="https://www.hygaar.com/" className="text-indigo-400">HyGaar.com</a></li>
            </ul>            
            <a href="/" className="p-[1px] mt-10 absolute">
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-white rounded-full" />
                <div className="px-10 py-3 bg-black rounded-full flex justify-center items-center gap-2 relative group transition duration-200 text-white hover:bg-transparent">
                    <FaChevronLeft />
                    Back to Home
                </div>
            </a>
            
          </div>

        </div>
      </div>

    </section>
  );
}
