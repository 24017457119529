import React from 'react'
import { motion, useScroll, useTransform } from "framer-motion";
import { FlipWords } from './ui/FlipWords'
import { useRef } from "react";

const words = [
  "Automated Video Creation",
  "Turn static images into short-form videos",
  "Advanced Image Analysis",
  "Intelligent Prompt Generation",
  "Multi-Model AI",
  "Automatic Logo & Text Overlays",
  "Bulk video creation with APIs",
  "Cost-effective",
  "Pay-As-You-Go Pricing",
];

const Hero = () => {
  
  const actionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: actionRef,
    offset: ["start start", "end start"],
  });

  const itemsY = useTransform(scrollYProgress, [0, 1], ["0px", "350px"]);

  return (
    <div className="relative pb-40 pt-36 h-screen" ref={actionRef}>

      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <video
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
          preload="auto"
          playsInline
        >
          <source src={`${process.env.PUBLIC_URL}/images/hygaar-hero-bg.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
            
      {/* Action div */}
      <div className="flex flex-col w-full h-full text-center justify-center items-center relative">
        <motion.div 
          className="text-center font-normal mb-4 text-2xl lg:text-6xl"
          style={{ fontFamily: "Gantari, sans-serif", y: itemsY }}
        >
          <FlipWords words={words} />
        </motion.div>
        <motion.img 
          className="my-2 -ms-2 object-cover justify-center items-center"
          src={`${process.env.PUBLIC_URL}/images/wordmark-logo.svg`}
          width="200"
          alt="HyGaar"
          style={{ y: itemsY }} />

        <motion.a
          href="#inter"
          className="p-[1px] mt-10 relative"
          style={{ y: itemsY }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-200 rounded-full" />
          <div className="px-10 py-3 bg-black rounded-full relative group transition duration-200 text-white hover:bg-transparent">
            Start Journey
          </div>
        </motion.a>

      </div>

    </div>
  )
}

export default Hero