import React from 'react'
import { BackgroundGradient } from "../components/ui/BackgroundGradient"
import { TextGenerateEffect } from './ui/TextGenerateEffect';

const Intermediate = () => {

  const words = "HyGaar uses multi-model AI to analyse & automatically turn static brand content into short-form videos with text overlays. Our APIs enable automated bulk video creation in a simple, cost-effective way.";

  return (
    <div id="inter" className="relative pt-48 pb-20 h-screen w-full">
        <div className="h-full w-full bg-grid-white/[0.1] relative flex items-center justify-center">
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-gray-950 [mask-image:radial-gradient(ellipse_at_center,transparent,black)]">
            </div>
            <div className='w-full text-center px-8 flex flex-col justify-center items-center'>
                <h1 data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="300"
                    className="text-4xl md:text-4xl lg:text-6xl font-light mx-auto text-center mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white"
                >
                    AI Powered Video Marketing
                </h1>
                <div 
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="600" 
                    className='flex lg:m-20 gap-10 text-left z-20 mt-6 items-start md:flex-row flex-col md:max-w-6xl'
                >
                    <TextGenerateEffect className="lg:mt-10 mt-6 order-2" duration={2} filter={false} words={words} />
                    <BackgroundGradient className="md:w-[50vh] lg:w-[40vw] h-full w-full order-1 md:mt-0 object-cover rounded-3xl overflow-hidden p-4 bg-zinc-900">
                        <video
                            className="w-full rounded-2xl h-full object-cover"
                            autoPlay
                            loop    
                            muted
                            preload="auto"
                            playsInline
                        >
                            <source src={`${process.env.PUBLIC_URL}/images/about-us-V2.mp4`} type="video/mp4" />
                                Your browser does not support the video tag.
                        </video>
                    </BackgroundGradient>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Intermediate