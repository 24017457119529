import React from "react";
import "./css/style.css";

import HeroHome from "./components/HeroHome";
import Workflows from "./components/Workflows";
import Features from "./components/Features";
import StatsCarousel from "./components/StatsCarousel";
import ContactUs from "./components/ContactUs";
import Chatbot from "./components/chatbot";
import Hero from "./components/Hero";
import Intermediate from "./components/Intermediate";
import Scroll from "./components/Scroll"
import ContactUsNew from "./components/ContactUsNew";
import ContactFiller from "./components/ContactFiller";

export default function Home() {
  return (
    <>

      {/* <div id="home"><HeroHome /></div>
      <div id="aboutus"><Features /></div>
      <div id="products"><Workflows /></div>
      <div id="stats"><StatsCarousel /></div>
      <div id="contactus"><ContactUs /></div> */}

      <Hero />
      <Intermediate />
      <Scroll />
      <Workflows />
      <ContactFiller />
      <div id="contact-us"><ContactUsNew /></div>
      <Chatbot />
    </>
  );
}
