"use client";
import React from "react";
import { BackgroundGradient } from "../components/ui/BackgroundGradient";

const Scroll = () => {

  return (
    <div className="relative pb-60 w-full">

      <div className="w-full text-center px-8 flex flex-col justify-center items-center">
        <div className="flex w-full mt-10 gap-10 md:flex-row text-center md:text-start lg:flex-row justify-center items-center flex-col md:max-w-6xl">
          <h1 className="text-4xl font-light mx-auto max-w-sm w-full mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white">
            Your Boring & Static Product Images
          </h1>
          <BackgroundGradient className="rounded-3xl w-100 overflow-hidden p-4 bg-zinc-900">
            <img
              className="w-100 rounded-2xl h-[36vh] md:h-[60vh] object-cover"
              src={`${process.env.PUBLIC_URL}/images/shoe_red.jpg`}
              alt="Red shoe"
            />
          </BackgroundGradient>
        </div>
      </div>

      <div className="w-full text-center px-8 flex flex-col justify-center items-center">
        <div className="flex w-full mt-10 gap-10 md:flex-row text-center md:text-start lg:flex-row justify-center items-center flex-col md:max-w-6xl">
          <h1 className="text-4xl font-light mx-auto max-w-sm w-full mt-6 relative z-20 py-6 bg-clip-text text-transparent bg-gradient-to-b from-indigo-600 via-white to-white">
            Are Now Dynamic & Engaging Videos
          </h1>
          <BackgroundGradient className="rounded-3xl w-150 overflow-hidden p-4 bg-zinc-900">
            <video
              className="w-100 rounded-2xl h-[36vh] md:h-[60vh] object-cover"
              autoPlay
              loop
              muted
              preload="auto"
              playsInline
            >
              <source src={`${process.env.PUBLIC_URL}/images/shoe_spin_overlay.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </BackgroundGradient>
        </div>
      </div>

    </div>
  );
};

export default Scroll;
