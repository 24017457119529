import Logo from "./Logo";
import { motion } from "framer-motion";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="relative bg-black-800 h-auto w-full px-4 pb-12 mt-10 text-center md:pb-15">
      <div className="relative z-10 grid mx-auto max-w-6xl sm:px-6 justify-center items-center grid-cols-1 sm:grid-cols-2">
        <div className="text-left">
          <motion.p
            className="text-xl md:text-3xl font-light text-center sm:text-left text-white mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            Elevate Your Experience with HyGaar
          </motion.p>
          <motion.p
            className="text-base text-center sm:text-left text-indigo-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
          >
            Embrace Innovation. Experience Quality.

          </motion.p>
        </div>

        <div className="text-center py-2 sm:text-right">
          <div className="flex md:justify-end my-10 md:my-3 justify-center items-end">
            <img 
              className="m-2 object-cover"
              src={`${process.env.PUBLIC_URL}/images/wordmark-logo.svg`}
              width="200"
              alt="HyGaar"
            />
          </div>
          <div className="text-sm">
            <p className="mb-6 text-indigo-300">
              © {new Date().getFullYear()} HyGaar.com
              <span className="text-white"> · </span>
              <a
                className="text-indigo-300 transition hover:text-indigo-500"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
            </p>
            <ul className="flex justify-center gap-4 sm:justify-end">
              <li>
                <a
                  className="flex items-center justify-center text-indigo-300 transition hover:text-indigo-400"
                  href="https://x.com/HyGaarTech"
                  aria-label="Twitter"
                >
                  <svg
                    className="h-7 w-8 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center justify-center text-indigo-300 transition hover:text-indigo-400"
                  href="https://www.instagram.com/hygaartech/profilecard/?igsh=M2E1ejJucGhqdXJh"
                  aria-label="Instagram"
                >
                  <FaInstagram className="h-7 w-8" />
                </a>
              </li>
              <li>
                <a
                  className="flex items-center justify-center text-indigo-300 transition hover:text-indigo-400"
                  href="https://www.linkedin.com/company/hygaar/"
                  aria-label="LinkedIn"
                >
                  <FaLinkedin className="h-7 w-8" />
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <img
        className="absolute bottom-0 right-0 z-0"
        src="/images/footer-illustration.svg"
        width="1076"
        height="378"
        alt="Footer illustration"
      />
    </footer>
  );
}
