import React, { useState } from "react";
import { LampContainer } from "./ui/LampContainer";
import { motion } from "framer-motion";

export default function ContactUsNew() {

  return (
    <section>
      <LampContainer>
        <motion.h1
            initial={{ opacity: 0.5, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
            }}
            className="text-2xl text-center mt-20 md:mt-40 lg:mt-40 md:text-6xl font-light mx-auto relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-indigo-500 via-white to-white"
        >
            Connect with us, open doors <br /> to new possibilities
        </motion.h1>
        <motion.a
          href="#contact-us"
          className="p-[1px] mt-10 relative"
          initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-white rounded-full" />
          <div className="px-10 py-3 bg-black rounded-full relative group transition duration-200 text-white hover:bg-transparent">
            Contact Us
          </div>
        </motion.a>
      </LampContainer>
    </section>
  );
}
